import { api, user } from '@/api'
import md5 from 'md5';
export default {
    name: 'Login',

    created () {
        // 获取数据
        this.handleGetData()
    },

    methods: {
        handleGetData () {
            if (this.isAjax) return
            this.isAjax = true
            api.about().then(res => {
                this.isAjax = false
                if (res.status != 1) return this.$toast(res.message);
                this.data = res.data
            }).catch(err => {
                console.log(err)
                this.isAjax = false
            })
        }
    },

    data() {
        return {
            isAjax: false,
            data: null,
        }
    }

}